import React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import Container from '../components/container'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';

const old = `
query recentlyUpdatedPagesDynamic($startN:Int, $limitN:Int){
  strapiSiteSetting {
      site_name
      default_seo_description
    }
  allStrapiPage(
      sort: "updatedAt:desc"
      limit: $limitN
      start: $startN
      publicationState: LIVE
    ) {
    nodes{
        id
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        excerpt
        publishedAt
        parent {
          id
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
}`
export const query = graphql`
  query recentlyUpdatedPagesDynamic($startN:Int, $limitN:Int){
    allStrapiPage(
      sort: {fields: updatedAt, order: DESC}
      limit: $limitN
      skip: $startN
    ) {
      nodes {
        id
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        excerpt
        publishedAt
        parent {
          id
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
  }
`

// markup
const LatestPages = props => {
  const {pageContext, data} = props
  const {messageContent} = pageContext
  const postNodes = data.allStrapiPage.nodes

  return (
    <Layout>
      <MetaSchemaSEO
      />
      <Container>
        { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
      </Container>
      <Container>
        <h1>Latest Pages</h1>
        <div>{messageContent}</div>
        <ul>
            {postNodes.map(post => (
                <li key={post.id}>
                  <Link to={getPageUrl(post)}>{post.title}</Link>
                </li>
            ))}
        </ul>
        { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
      </Container>
    </Layout>
  )
}

export default LatestPages
